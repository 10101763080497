import { useField, useFormikContext } from 'formik';
import styled, { css } from 'styled-components';
import Text from '../../base/Text';
import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';
import { usePermissionAwareDisabled } from '../../../contexts/TeamPermissionContext';
import { TeamRole } from '../../../../../../../typings/TeamMember.interface';
import Icon, { IconName } from '../../base/Icon';

interface Props extends StyledUtilsProps {
  label?: string;
  name: string;
  icon?: IconName;
  help?: string;
  required?: boolean;
  disabled?: boolean;
  highlight?: boolean;
  role?: TeamRole;
}

export const StyledCheckbox = styled(Div)<{
  highlight?: boolean;
  disabled?: boolean;
  has_label: boolean;
}>(
  ({ theme, disabled, highlight, has_label }) => css`
    display: flex;
    align-items: flex-start;
    height: fit-content;
    cursor: pointer;

    input {
      ${has_label &&
      css`
        margin-top: ${theme.spacing(0.5)};
        margin-bottom: ${theme.spacing(0.5)};
        margin-right: ${theme.spacing(2)};
      `}
      appearance: none;
      background-color: ${theme.colors.surface.base.surface};
      position: relative;

      min-width: ${theme.pxToRem(15)};
      min-height: ${theme.pxToRem(15)};
      border: 1px solid ${theme.colors.outline.neutral};
      border-radius: ${theme.radius.xsmall};

      display: grid;
      place-content: center;

      &:hover:not(:disabled) {
        border-color: ${theme.colors.outline.hover.neutral};
        cursor: pointer;
      }

      &:checked:not(:disabled) {
        border-color: ${theme.colors.surface.base.primary};
        background-color: ${theme.colors.surface.base.primary};
      }

      &:disabled {
        border: 1px solid ${theme.colors.outline.neutral};
        background-color: ${theme.colors.surface.base.disabled};
        cursor: not-allowed;
      }

      &:checked {
        &::after {
          content: '';
          height: ${theme.pxToRem(6)};
          width: ${theme.pxToRem(8)};
          background-repeat: no-repeat;
          background-position: center;
          background-image: ${`url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.66661 4.28333L6.73328 0.216659C6.8555 0.0944363 6.99717 0.0333252 7.15828 0.0333252C7.31939 0.0333252 7.46105 0.0944363 7.58328 0.216659C7.7055 0.338881 7.76661 0.480547 7.76661 0.641659C7.76661 0.80277 7.7055 0.944436 7.58328 1.06666L3.08328 5.56666C2.96105 5.68888 2.81939 5.74999 2.65828 5.74999C2.49717 5.74999 2.3555 5.68888 2.23328 5.56666L0.41661 3.74999C0.294387 3.62777 0.233276 3.4861 0.233276 3.32499C0.233276 3.16388 0.294387 3.02221 0.41661 2.89999C0.538832 2.77777 0.680499 2.71666 0.84161 2.71666C1.00272 2.71666 1.14439 2.77777 1.26661 2.89999L2.66661 4.28333Z' fill='${(disabled
            ? theme.colors.on.neutral.disabled
            : theme.colors.on.hue.primary
          ).replace('#', '%23')}'/%3E%3C/svg%3E%0A")`};
        }
      }

      &:focus {
        outline: none;
      }
      &:focus-visible {
        &::before {
          content: '';
          position: absolute;
          top: -4px;
          left: -4px;
          right: -4px;
          bottom: -4px;
          border: 2px solid ${theme.colors.outline.focus.primary};
          border-radius: ${theme.radius.small};
        }
      }
    }

    ${highlight &&
    !disabled &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.surface.base.background};
      }
    `}
  `,
);

const CheckboxInput: React.FC<Props> = ({ label, name, required, help, role, icon, ...props }) => {
  const { submitCount } = useFormikContext();
  const [field, { error, touched }] = useField(name);

  const disabled = usePermissionAwareDisabled(props.disabled, role);

  return (
    <StyledCheckbox as="label" has_label={!!label} {...props} disabled={disabled}>
      <input
        {...field}
        checked={!!field.value}
        disabled={disabled}
        required={required}
        type="checkbox"
      />
      <div>
        {label && (
          <Text>
            {icon && <Icon left icon={icon} />}
            {label}
          </Text>
        )}
        {help && (
          <Text as="p" m={{ t: 1, b: 0 }} muted>
            {help}
          </Text>
        )}
        {(touched || submitCount > 0) && error && (
          <Text as="p" m={{ t: 1, b: 0 }} danger>
            {error}
          </Text>
        )}
      </div>
    </StyledCheckbox>
  );
};

export default CheckboxInput;
