import { useField, useFormikContext } from 'formik';
import styled, { css } from 'styled-components';
import Text from '../../base/Text';
import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';
import { usePermissionAwareDisabled } from '../../../contexts/TeamPermissionContext';

interface Props extends StyledUtilsProps {
  options: { value: string; label: string; disabled?: boolean }[];
  name: string;
  help?: string;
  inline?: boolean;
  required?: boolean;
  disabled?: boolean;
  highlight?: boolean;
}

export const StyledRadio = styled(Div)<{
  highlight?: boolean;
  disabled?: boolean;
  inline: boolean;
  checked: boolean;
}>(
  ({ theme, highlight, disabled, inline }) => css`
    display: flex;
    align-items: center;
    font-size: ${theme.pxToRem(13)};

    ${inline
      ? css`
          &:not(:last-child) {
            margin-right: ${theme.spacing(3)};
          }
        `
      : css`
          &:not(:last-child) {
            margin-bottom: ${theme.spacing(2)};
          }
        `}

    cursor: pointer;
    &:hover {
      cursor: pointer;
    }
    ${disabled &&
    css`
      cursor: not-allowed;
      &:hover {
        cursor: not-allowed;
      }
    `}

    input {
      margin-right: ${theme.spacing(2)};

      appearance: none;
      background-color: #fff;

      min-width: ${theme.pxToRem(18)};
      min-height: ${theme.pxToRem(18)};
      border: 1px solid ${theme.colors.outline.neutral};
      border-radius: 50%;
      display: grid;
      place-content: center;
      background-color: ${theme.colors.surface.base.surface};

      &:hover:not(:disabled) {
        border-color: ${theme.colors.outline.hover.neutral};
        cursor: pointer;
      }

      &:checked:not(:disabled) {
        border-color: ${theme.colors.outline.primary};
        background-color: ${theme.colors.surface.base.primary};
      }

      &:disabled {
        border: 1px solid ${theme.colors.outline.neutral};
        background-color: ${theme.colors.surface.base.disabled};
        color: ${theme.colors.on.neutral.secondary_neutral};
        cursor: not-allowed;
      }

      &:disabled + span {
        color: ${theme.colors.on.neutral.secondary_neutral};
      }

      &:checked {
        &::before {
          content: '';
          height: 8px;
          width: 8px;
          background-color: ${disabled
            ? theme.colors.on.neutral.disabled
            : theme.colors.on.hue.primary};
          border-radius: 50%;
        }
      }
    }

    ${highlight &&
    !disabled &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.surface.base.background};
      }
    `}
  `,
);

const RadioGroupInput: React.FC<Props> = (props) => {
  const { options, required, name, help, disabled: _disabled, inline } = props;
  const field_disabled = usePermissionAwareDisabled(_disabled);
  const { submitCount } = useFormikContext();
  const [field, { error, touched }] = useField(name);

  return (
    <div role="group">
      <Div flex={{ direction: inline ? 'row' : 'column' }}>
        {options.map(({ value, label, disabled }) => (
          <StyledRadio
            key={value}
            as="label"
            inline={inline || false}
            checked={field.value === value}
            {...props}>
            <input
              {...field}
              checked={field.value === value}
              value={value}
              name={name}
              disabled={field_disabled || disabled}
              required={required}
              type="radio"
            />
            <span>{label}</span>
          </StyledRadio>
        ))}
      </Div>
      {help && (
        <Text m={{ top: 1 }} as="span" muted>
          {help}
        </Text>
      )}
      {(touched || submitCount > 0) && error && (
        <Text m={{ top: 1, bottom: 0 }} as="p" danger>
          {error}
        </Text>
      )}
    </div>
  );
};

export default RadioGroupInput;
